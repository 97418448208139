@import '../../../../scss/theme-bootstrap';

.product-brief {
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
  text-align: center; // Fix for IE to aligne inside items
  width: 100%;
  align-items: center;
  @include breakpoint($medium-up) {
    margin-bottom: 20px;
  }
  &.IE-min-height-processed {
    min-height: 800px;
  }
  &__flag {
    display: table;
    margin: 0 auto;
    min-height: 0;
    height: 27px;
    &--preorder {
      padding: 0 12px;
      margin: 0 auto 10px;
      display: none;
    }
    &--text,
    &--subheader {
      padding: 0 12px;
      margin: 0 auto;
      border: 1px solid $color-black-2;
    }
  }
  &__image-wrapper {
    margin: 0 auto;
    position: relative;
    width: 78%;
    @include breakpoint($medium-up) {
      &--has-alt-image:hover {
        .product-brief__image {
          opacity: 0;
          visibility: hidden;
        }
        .product-brief__image--alt {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &__image-container {
    width: 100%;
    .product-brief__image-badge {
      pointer-events: none;
      position: absolute;
      top: 0;
      #{$rdirection}: 0;
      z-index: 1;
    }
  }
  &__image {
    margin: 0 auto;
    max-height: 380px;
    max-width: 100%;
    opacity: 1;
    visibility: visible;
    text-align: center;
    transition: opacity 1s ease-in-out;
    width: auto;
    &.product-brief__image--alt {
      #{$ldirection}: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      visibility: hidden;
      z-index: 1;
    }
  }
  &__image-link {
    text-decoration: none;
    color: $color-black-2;
    font-size: 13px;
    &:hover {
      text-decoration: none;
    }
    &.slick-slide {
      .product-brief__image--alt {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__cta {
    @include breakpoint($medium-up) {
      transition: opacity 0.3s ease, visibility 0.3s ease;
      opacity: 0;
      visibility: hidden;
    }
  }
  &__shades {
    min-height: 50px;
    width: 100%; // do not remove, messes up the slider on mobile, adjust if needs to be smaller
    @include breakpoint($medium-up) {
      overflow: hidden;
      min-height: 88px;
      max-width: 325px;
      min-width: 280px;
      padding: 0 15px;
      margin: 0 auto;
    }
    .slick-slide & {
      @include breakpoint($landscape-up) {
        min-height: 90px;
      }
    }
  }
  &__abstract {
    flex: 1;
    width: 100%;
    text-align: center;
    max-width: 345px;
    margin: 0 auto;
    .tabbed-products-block & {
      max-width: 330px;
      @include breakpoint($medium-up) {
        max-width: 290px;
      }
    }
    @include breakpoint($medium-up) {
      max-width: 325px;
    }
    .p-w-r {
      .pr-snippet {
        padding: 0 0 4px;
      }
    }
  }
  &__headline {
    display: inline-block;
    width: 100%;
    &-link {
      color: $color-black-2;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
    @include breakpoint($medium-up) {
      display: block;
      width: 100%;
    }
  }
  &__header {
    color: $color-black-2;
    font-family: $body-font-bold;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
  }
  &__short-desc {
    font-size: 15px;
    line-height: 1.375;
    margin-top: 3px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    &-link {
      color: $color-black-2;
      font-size: 13px;
      text-transform: uppercase;
      white-space: nowrap;
    }
    @include breakpoint($medium-up) {
      margin-top: 0;
      text-align: center;
    }
  }
  &__bottom {
    width: 100%;
    text-align: center;
    margin: -8px auto 0;
    max-width: 345px;
    .tabbed-products-block & {
      max-width: 330px;
    }
  }
  &__bottom-mobile {
    width: 100%;
    .product-brief__short-desc-link {
      width: 100%;
      text-align: center;
      font-size: 12px;
      height: 44px;
      line-height: 48px;
      @include breakpoint($medium-up) {
        height: auto;
        padding-top: 12px;
      }
    }
    .product-brief__add-to-bag {
      width: 100%;
      text-align: center;
      margin-top: 15px;
      .product__button--add-to-bag {
        @include button;
        width: 150px;
        height: auto;
        min-width: 150px;
        @include breakpoint($medium-up) {
          width: auto;
          max-width: 238px;
        }
      }
      .button--disabled {
        @include button--disabled;
        curson: default;
        height: auto;
        line-height: 1;
        min-width: 150px;
        @include breakpoint($medium-up) {
          width: auto;
          max-width: 238px;
        }
      }
      .product__notify-me {
        @include breakpoint($medium-up) {
          display: block;
          margin: 0 auto;
          width: 69%;
          max-width: 238px;
        }
        > li {
          width: 100%;
          .button {
            @include button;
            margin: 0;
            height: auto;
            line-height: 1.6;
            min-width: 150px;
            @include breakpoint($medium-up) {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__inventory_status {
    display: block;
    width: 100%;
    height: auto;
    color: $color-warm-grey;
    font-size: 13px;
    text-align: center;
    padding: 4px 0;
  }
  // overwrite locals alignment
  .product__inventory-status {
    .preorder-noshop {
      position: relative;
      bottom: 0;
      width: auto;
      @include breakpoint($medium-up) {
        float: none;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
  .p-w-r .pr-category-snippet__total {
    padding: 5px 0 0 0;
    font-family: $body-font;
    font-size: 13px;
  }
  .p-w-r .pr-category-snippet__rating {
    @include swap_direction(padding, 3px 5px 3px 0);
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0 5px 0 0);
    }
  }
  &__price {
    font-size: 15px;
    margin: 2px 0 7px;
    &.pc-hidden {
      display: block;
      margin: 3px 0 9px;
      text-align: center;
      vertical-align: top;
      width: 100%;
    }
    .price-unit-price {
      color: $color-warm-grey;
    }
  }
  &__quickshop-buttons {
    .product-brief {
      &__add-to-bag {
        display: inline-block;
      }
      &__quick-shop-cta {
        display: none;
        @include breakpoint($large-up) {
          display: inline-block;
          & ~ .product-brief__add-to-bag {
            //only hide when QS CTA is available
            display: none;
          }
        }
      }
    }
  }
  &__product-info-wrapper {
    display: flex;
    flex-direction: column;
    flex: initial;
    width: 100%;
    @include breakpoint($medium-up) {
      max-width: 100%;
    }
    .product-brief__bottom {
      .product-brief__bottom-mobile {
        .product-brief__add-to-bag {
          .product__button {
            letter-spacing: 0;
          }
        }
      }
    }
    .product-sized__title-buttons {
      .sku-list__list {
        min-height: 88px !important;
      }
    }
  }
}
